<template>
  <div>
    <div
      class="menu-body pt-2"
      v-infinite-scroll="retrieveData"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="5"
    >
      <!-- Infinity scroll to show result in batch of 10 -->
      <div class="menu-activity-collection pt-1">
        <PageLoader
          v-show="pageLoading"
          :loading="pageLoading"
          replace_msg="Fetching History">
        </PageLoader>

        <div
          v-show="!pageLoading"
          class="p-1 border-bottom"
          v-for="(item, index) in lists"
          :key="index"
        >
          <div class="row mt-2">
            <div class="col">
              <h6 class="fw-bold text-darkgrey">
                {{ item.name }}
              </h6>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <b class="text-muted fs-small">
                {{ item.created_at | formatDate }}
              </b>
              <p class="text-muted fs-small">
                {{ extractFirstName(item.creator.name) }}
              </p>
            </div>

            <div class="col">
              <p
                :class="[
                  'btn w-100',
                  item.completedPerCentage >= 100
                    ? 'btn-primary-outlined'
                    : 'btn-warning-outlined'
                ]"
                v-if="item.completedPerCentage >= 100 || true"
                @click="
                  item.completedPerCentage >= 100 ||
                  allowOpen.includes(item.type ?? false)
                    ? openCase(item)
                    : $toast.warning('Still in progress.')"
              >
                {{
                  item.completedPerCentage >= 100
                    ? "Open" : "Processing"
                }}
                <b-spinner
                  v-if="item.completedPerCentage < 100"
                  label="Spinning"
                  class="spinner">
                </b-spinner>
              </p>
            </div>
          </div>
        </div>

        <div
          v-show="!pageLoading && lists.length === 0"
          class="empty-placeholder"
        >
          <div>
            <img
              class="mb-3"
              src="@/assets/noData.png"
              alt="No Data Image"
            />
            <p class="text-center text-blue font-weight-bold">
              {{ "No Record" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import PageLoader from "../PageLoader.vue";
import Auth from "@/store/Auth.js";
export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      pageLoading: true,
      allowOpen: [
        "argument",
        "memo",
        "LOD",
        "les",
      ],
      lists: [], // This will hold the activity data
      page: 0, // Current page of data
    };
  },

  methods: {
    extractFirstName(fullName) {
      return fullName
        .split(" ")
        .slice(0, 2)
        .join(" ");
    },

    retrieveData() {
      // Infinite scroll method to fetch more data when the user scrolls to the bottom
      this.page = this.page + 1;
      var obj = `?page=${this.page}`;

      if (
        JSON.parse(localStorage.userInfo).not_have_plan_yet !== "1" &&
        JSON.parse(localStorage.userInfo).not_have_plan_yet !== 1
      ) {
        Auth.allNexlawPage(obj)
          .then((response) => {
            this.pageLoading = false;
            response.data.data.data.forEach((element) => {
              if ([
                  "lod_outstanding_payment",
                  "lod_insurance",
                  "lod_property_damage",
                ].includes(element.compose_type)
              ) {
                [element.name, element.icon, element.color] = [
                  "Letter Of Demand", "payment", "icon-grey"
                ];
              } else if ([
                  "agreement_partnership",
                  "agreement_snp",
                  "agreement_nda",
                  "others",
                  "custom",
                ].includes(element.compose_type)
              ) {
                [element.name, element.icon, element.color] = [
                  "Agreement", "payment", "icon-grey"
                ];
              }

              switch(element.type) {
                case "statement":
                  [element.name, element.icon, element.color] = [
                    "Case Law Search", "handshake", "icon-blue"
                  ]; break;

                case "case_summarizer":
                  [element.name, element.icon, element.color] = [
                    "Case Law Summarizer", "description", "icon-darkblue"
                  ]; break;

                case "contract_review":
                  [element.name, element.icon, element.color] = [
                    "Contract Review", "task", "icon-blue-border"
                  ]; break;

                case "argument":
                  [element.name, element.icon, element.color] = [
                    "Legal Argument", "account_balance", "icon-blue-border"
                  ]; break;

                case "memo":
                  [element.name, element.icon, element.color] = [
                    "Legal Research Memo and Opinion", "subject", "icon-blue-border"
                  ]; break;

                case "legislation":
                  [element.name, element.icon, element.color] = [
                    "Legislation Search", "search", "icon-blue-border"
                  ]; break;

                case "les":
                  [element.name, element.icon, element.color] = [
                    "Legal AI Trial Copilot", "gavel", "icon-darkblue"
                  ]; break;
              }

              this.lists.push(element);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    openCase(data) {
      var routeObj = {
        params: {
          id: data.id,
        }
      }

      switch(data.type) {
        case "statement":
          routeObj.name = "Case_Law_Search_Cases_jurisdiction"; break;

        case "legislation":
          routeObj.name = "Legislation_jurisdiction"; break;

        case "case_summarizer":
          routeObj.name = "Case_Summarizer_CaseSummary"; break;
          
        case "contract_review":
          routeObj.name = "ContractDocument"; break;

        case "LOD":
          routeObj.name = "LegalWriterEdit";
          routeObj.query = {
            category: this.$options.filters.composeType(data.compose_type),
          };
          break;
          
        case "argument":
        case "memo":
          routeObj.name = "LegalResearchAISuggestion";
          routeObj.query = {
            category: this.$options.filters.composeType(data.type),
          };
          break;
        
        case "les":
          routeObj.name = "LegalExpertSystemPreTrialResult";
          routeObj.params.category = "preTrial"; break;
      }

      if (routeObj.name) {
        this.$router.push(routeObj);
      }
    },
    
    sendMessage() {
      this.$emit("message", false);
    },
  },
};
</script>
<style lang="css" scoped>
.menu-content .menu-heading {
  padding: 1.3rem 1rem 1rem;
  border-bottom: var(--color-primary) 1px solid;
  display: flex;
  align-items: center;
  height: 63px;
}
.menu-content .menu-heading h5 {
  margin: 0;
}

.menu-content .menu-heading .btn-close {
  margin-left: auto;
  color: #6c757d;
  padding: 8px 0 0 0;
}
.menu-content .menu-heading .btn-close:focus {
  border: none;
  box-shadow: none;
}
.menu-content .menu-body {
  /* margin-top: 1rem; */
  overflow: auto;
  padding: 0rem 0rem 1rem 1rem;
  height: 95vh;
  display: flex;
  flex-direction: column;
}
.menu-info-collection {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 0rem 1rem 0rem 0rem;
}

.btn {
  background-color: #f8f9fa !important;
}

.btn-primary-outlined:hover {
  background-color: #013d82 !important;
  color: white !important;
}

.btn-warning-outlined:hover {
  background-color: #f08000 !important;
  color: white !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

.empty-placeholder {
  width: 100%;
  height: calc(100vh - 4.5rem);

  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-placeholder div {
  display: flex;
  flex-direction: column;
}
</style>
