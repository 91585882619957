<template>
  <div>
    <!-- Tutorial Videos Pop Up-->
    <b-modal
      id="subscribe-show-video"
      centered
      hide-footer
      hide-header
      size="xl"
    >
      <div class="card-body">
        <video
          class="rounded shadow videoPlay"
          controls
          autoplay
          controlslist="nodownload noplaybackrate"
        >
          <source :src="chosenVideo" type="video/mp4" />
        </video>
      </div>
    </b-modal>
    <!-- Tutorial Videos Pop Up-->

    <div class="page-wrapper-md">
      <h2 class="color-primary mt-4 mb-3 font-weight-bold">
        {{ "Welcome " }}{{ firstName }} &#x1F44B;
      </h2>

      <!-- Tutorial Videos -->
      <div class="py-3">
        <h3 class="color-darkgrey font-weight-bold">
          {{ "Explore with NexLaw" }}
        </h3>
        <p class="color-darkgrey">
          {{ "Check out these tutorial videos !" }}
        </p>

        <div class="demo-container">
          <div class="carousel">
            <!-- Carousel Navigations -->
            <input
              v-for="n in [1,2,3,4]"
              :key="`c-activator-${n}`"
              :checked="n === 1"
              :id="`carousel-slide-activator-${n}`"
              class="carousel__activator"
              name="carousel"
              type="radio"
            />
            <div
              v-for="n in [2,3,4,1]"
              :key="`c-control-${n}`"
              class="carousel__controls"
            >
              <label
                :class="`carousel__control carousel__control--${ n === 1 ? 'back' : 'for' }ward`"
                :for="`carousel-slide-activator-${n}`"
              >
                <span class="material-icons-outlined arrow_right">
                  {{ `arrow_circle_${ n === 1 ? "left" : "right" }` }}
                </span>
              </label>
            </div>
            <div class="carousel__screen">
              <div class="carousel__track">
                <div
                  v-for="(vid, index) in videos"
                  :key="`vid-${index}`"
                  class="carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-3"
                >
                  <img
                    class="rounded shadow img-fluid"
                    alt=""
                    :src="vid.poster"
                    @click="chosen_video(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tutorial Videos -->

      <!-- Recent Activities -->
      <section class="py-4 mt-3" v-if="history_result.length != 0">
        <h3 class="color-darkgrey font-weight-bold">
          {{ "Recent Activities" }}
        </h3>
        <p class="color-darkgrey">{{ "Your recent actions on Nexlaw" }}</p>

        <PageLoader
          v-if="pageLoading"
          :loading="pageLoading"
          replace_msg="Fetching History"
        >
        </PageLoader>

        <div v-else class="d-flex w-100" style="margin-right: -2rem">
          <div class="row d-flex w-100 g-4">
            <div
              class="col-3 col-md-3 mb-4 mb-md-0"
              v-for="(n, index) in history_result"
              :key="index"
            >
              <div class="d-flex flex-column h-100">
                <button class="button-sq_shadow h-100" @click="chooseLaw(n)">
                  <div class="m-auto mb-2">
                    <!-- icon -->
                    <span
                      class="material-icons-outlined button-dahsboard_icon"
                      :class="n.color"
                    >
                      {{ n.icon }}
                    </span>
                  </div>
                  <div class="text-center mt-auto">
                    <!-- label -->
                    <small class="color-darkgrey fw-bold">{{ n.name }}</small>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column">
            <button class="btn-view_history h-100" v-b-toggle.activity-sidebar>
              <div class="m-auto mb-2">
                <!-- icon -->
                <span
                  class="material-icons-outlined arrow_right arrow_right_history mt-4"
                >
                  arrow_circle_right
                </span>
              </div>
              <div class="text-center mt-auto">
                <!-- label -->
                <small class="color-primary fw-bold">{{
                  "View History"
                }}</small>
              </div>
            </button>
          </div>
        </div>
      </section>
      <!-- Recent Activities -->

      <!-- Upgrade Plans -->
      <!-- <section class="py-4">
        <div
          class="nexcard gradientblue-bg mt-3 mb-3 d-flex flex-column flex-md-row"
        >
          <div class="mx-auto me-md-auto ms-md-0 d-flex flex-column">
            <h3 class="text-white fw-bold text-center text-md-start">
              {{ "Elevate your Legal Experience" }}
            </h3>
            <p class="text-white text-center text-md-start">
              {{ "Upgrade your plans to access with more features" }}
            </p>
            <router-link
              v-if="userInfo.has_subscription === false"
              to="/dashboard"
              class="mx-auto me-md-auto ms-md-0 mt-auto"
            >
              <button class="btn btn-white w-100">
                {{ "Upgrade now" }}
              </button>
            </router-link>

            <router-link
              v-else
              to="/settings/billing_details"
              class="mx-auto me-md-auto ms-md-0 mt-auto"
            >
              <button class="btn btn-white w-100">
                {{ "Upgrade now" }}
              </button>
            </router-link>
          </div>
          <div class="d-flex mt-5 mt-md-0">
            <img
              src="../assets/dashboard/dashboard-upgrade.png"
              alt="upgrade"
              class="nexcard-img"
            />
          </div>
        </div>
      </section> -->
      <!-- Upgrade Plans -->

      <!-- Linked In -->
      <section class="py-4">
        <div class="nexcard grey-bg d-flex flex-column flex-md-row">
          <div class="mx-auto me-md-auto ms-md-0 d-flex flex-column">
            <h3 class="color-primary fw-bold text-center text-md-start">
              {{ "Connect with Us" }}
            </h3>
            <p class="color-primary text-center text-md-start">
              {{ "Stay in the loop with our Latest News and Updates" }}
            </p>
            <div class="d-flex mt-auto socials mx-auto me-md-auto ms-md-0">
              <a
                href="https://www.linkedin.com/company/nexlaw-ai"
                target="_blank"
                class="mx-3 ms-md-0"
              >
                <img
                  src="../assets/icons/icon-linkedin-blue.svg"
                  alt="linkedin"
                  style="height: 40px"
                />
              </a>
              <!-- <a
                href="https://www.quora.com/profile/NexMind-AI"
                target="_blank"
                class="mx-3"
              >
                <img
                  src="../assets/icons/icon-quora-blue.svg"
                  alt="quora"
                  style="height: 40px"
                />
              </a>
              <a
                href="https://www.reddit.com/user/Nexmind/"
                target="_blank"
                class="mx-3"
              >
                <img
                  src="../assets/icons/icon-reddit-blue.svg"
                  alt="reddit"
                  style="height: 40px"
                />
              </a> -->
            </div>
          </div>
          <div class="d-flex mt-5 mt-md-0">
            <img
              src="../assets/dashboard/dashboard-community.png"
              alt="upgrade"
              class="nexcard-img"
            />
          </div>
        </div>
      </section>
      <!-- Linked In -->
    </div>

    <!-- <div class="deco-container">
      <EventDecoration></EventDecoration>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "./PageLoader.vue";
import Auth from "@/store/Auth.js";
import Activity from "./SidebarRight-menus/Activity.vue";
import EventDecoration from "./EventDecoration.vue";
import WelcomeAbroad from "./WelcomeAbroad.vue";

export default {
  components: {
    PageLoader,
    Activity,
    EventDecoration,
    WelcomeAbroad,
  },

  data() {
    return {
      pageLoading: true,
      history_result: [],
      userInfo: JSON.parse(localStorage.userInfo),
      firstName: "",

      chosenVideo:
        "https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/v5/nexlaw+demo+-Dashboard+Overview.mp4",
      videoSource:
        "https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/v5/",
      videos: [
        {
          poster: "WelcomeAbroad.png",
          video:
            "https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/Nexlaw+Onboarding+Video.mp4",
        },
        {
          poster: "Overview.png",
          video: "NexLaw+Video+-+Dashboard+Overview_V2.mp4",
        },
        {
          poster: "caseLawSearch.png",
          video: "NexLaw+Video+-+Case+law+Search+2024.mp4",
          subtitle: "Case Law Search",
        },
        {
          poster: "legislationSearch.png",
          video: "NexLaw+Video+-+Legislation+Search_Jan+24.mp4",
          subtitle: "Legislation Search",
        },
        {
          poster: "caseSummerizer.png",
          video: "NexLaw+Video+-+Case+Law+Summarizer+2024.mp4",
          subtitle: "Case Law Summarizer",
        },
        {
          poster: "contractReview.png",
          video: "NexLaw+Video_Contract+Review_Jan+30124.mp4",
          subtitle: "Contract Review",
        },
        {
          poster: "lodLegalWriter.png",
          video: "NexLaw+Video_Legal+Writer+LOD_Jan+24.mp4",
          subtitle: "Letter of Demand",
        },
        {
          poster: "agreementLegalWriter.png",
          video: "NexLaw+Video_Legal+Writer+Agr_Jan+24.mp4",
          subtitle: "Agreement",
        },
        {
          poster: "legalArgument.png",
          video: "NexLaw+Video_Legal+Res+Argue_Jan+24.mp4",
          subtitle: "Legal Argument",
        },
        {
          poster: "LegalMemo.png",
          video: "NexLaw+Video_Legal+Res+Memo1_Jan+24.mp4",
          subtitle: "Legal Memo & Opinion",
        },
      ]
    };
  },

  methods: {
    async LoadVideoPoster() {
      for(let i = 0; i < this.videos.length; i++) {
        var posterPath;
        try {
          posterPath = await import(`@/assets/video/${this.videos[i].poster}`);
        } catch (error) {
          // idk y its not working correctly when importing this particular image
          posterPath = await import('@/assets/video/WelcomeAbroad.png');
        }

        this.videos[i].poster = posterPath.default || posterPath;
      }
    },

    chosen_video(index) {
      this.chosenVideo = index === 0
        ? this.videos[index].video
        : `${this.videoSource}${this.videos[index].video}`;
      this.$bvModal.show("subscribe-show-video");
    },

    chooseLaw(data) {
      // construct OBJ with route Name & Id
      const OBJ = {
        name: {
          "les": "LegalExpertSystemPreTrialResult",
          "argument": "LegalResearchAISuggestion",
          "memo": "LegalResearchAISuggestion",
          "case_summarizer": "Case_Summarizer_CaseSummary",
          "statement": "Case_Law_Search_Cases_jurisdiction",
          "legislation": "Legislation_jurisdiction",
          "LOD": "LegalWriterEdit",
          "contract_review": "ContractDocument",
        }[data.type],
        params: {
          id: data.id,
        },
      };

      // add extra parameters/query for specific route
      switch(data.type) {
        case "les":
          OBJ.params.category = "preTrial";
          break;

        case "argument":
        case "memo":
          OBJ.query = {
            category: this.$options.filters.composeType(data.type),
          };
          break;

        case "LOD":
          OBJ.query = {
            category: this.$options.filters.composeType(data.compose_type),
          };
          break;
      }

      this.$router.push(OBJ);
    },
  },

  created() {
    var names = this.userInfo.name.split(" ");
    this.firstName = names[0];

    this.LoadVideoPoster();

    Auth.allNexlaw()
      .then((response) => {
        this.pageLoading = false;
        response.data.data.data.slice(0, 4).forEach((element) => {
          if (element.type == "statement") {
            element.name = "Case Law Search";
            element.icon = "search";
            element.color = "icon-blue";
          } else if (element.type == "case_summarizer") {
            element.name = "Case Law Summarizer";
            element.icon = "description";
            element.color = "icon-darkblue";
          } else if (
            element.compose_type == "lod_outstanding_payment" ||
            element.compose_type == "lod_insurance" ||
            element.compose_type == "lod_property_damage"
          ) {
            element.name = "Letter Of Demand";
            element.icon = "edit";
            element.color = "icon-grey";
          } else if (
            element.compose_type == "agreement_partnership" ||
            element.compose_type == "agreement_snp" ||
            element.compose_type == "agreement_nda" ||
            element.compose_type == "others" ||
            element.compose_type == "custom"
          ) {
            element.name = "Agreement";
            element.icon = "edit";
            element.color = "icon-grey";
          } else if (element.type == "contract_review") {
            element.name = "Contract Review";
            element.icon = "task";
            element.color = "icon-blue-border";
          } else if (element.type == "argument") {
            element.name = "Legal Argument";
            element.icon = "account_balance";
            element.color = "icon-blue-border";
          } else if (element.type == "memo") {
            element.name = "Legal Research Memo and Opinion";
            element.icon = "subject";
            element.color = "icon-blue-border";
          } else if (element.type == "legislation") {
            element.name = "Legislation Search";
            element.icon = "search";
            element.color = "icon-blue";
          } else if (element.type == "les") {
            element.name = "Legal AI Trial Copilot";
            element.icon = "gavel";
            element.color = "icon-darkblue";
          }

          this.history_result.push(element);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* carousel */
.carousel {
  /* min-width: 900px; */
  max-width: 1236px;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 48px; */
  padding-right: 80px;
  position: relative;
}

.carousel__activator {
  display: none;
}

.carousel__controls {
  display: none;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0px;
  /* left: 16px; */
  bottom: 0;
}

.carousel__controls:first-of-type {
  justify-content: flex-end;
}

.carousel__controls:last-of-type {
  justify-content: flex-start;
}

.carousel__control {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #3d414a;
  border-radius: 100%;
  box-shadow: 0 2px 10px 0 rgba(33, 34, 36, 0.3);
  font-size: 24px;
  height: 48px;
  justify-content: center;
  transition: 0.3s all;
  width: 48px;
  z-index: 1;
}

.carousel__control:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.carousel__activator:nth-of-type(1):checked
  ~ .carousel__controls:nth-of-type(1) {
  display: flex;
}

.carousel__activator:nth-of-type(1):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.carousel__activator:nth-of-type(2):checked
  ~ .carousel__controls:nth-of-type(2) {
  display: flex;
}

.carousel__activator:nth-of-type(2):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel__activator:nth-of-type(3):checked
  ~ .carousel__controls:nth-of-type(3) {
  display: flex;
}

.carousel__activator:nth-of-type(3):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
}

.carousel__activator:nth-of-type(4):checked
  ~ .carousel__controls:nth-of-type(4) {
  display: flex;
}

.carousel__activator:nth-of-type(4):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-300%);
  transform: translateX(-300%);
}

.carousel__activator:nth-of-type(5):checked
  ~ .carousel__controls:nth-of-type(5) {
  display: flex;
}

.carousel__activator:nth-of-type(5):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-400%);
  transform: translateX(-400%);
}

/* 
.carousel__activator:nth-of-type(6):checked
  ~ .carousel__controls:nth-of-type(6) {
  display: flex;
}

.carousel__activator:nth-of-type(6):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-500%);
  transform: translateX(-500%);
} */

/* .carousel__activator:nth-of-type(7):checked
  ~ .carousel__controls:nth-of-type(7) {
  display: flex;
}

.carousel__activator:nth-of-type(7):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-600%);
  transform: translateX(-600%);
}

.carousel__activator:nth-of-type(8):checked
  ~ .carousel__controls:nth-of-type(8) {
  display: flex;
}

.carousel__activator:nth-of-type(8):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-700%);
  transform: translateX(-700%);
}

.carousel__activator:nth-of-type(9):checked
  ~ .carousel__controls:nth-of-type(9) {
  display: flex;
}

.carousel__activator:nth-of-type(9):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-800%);
  transform: translateX(-800%);
}

.carousel__activator:nth-of-type(10):checked
  ~ .carousel__controls:nth-of-type(10) {
  display: flex;
}

.carousel__activator:nth-of-type(10):checked
  ~ .carousel__screen
  .carousel__track {
  -webkit-transform: translateX(-900%);
  transform: translateX(-900%);
} */

.carousel__screen {
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
}

.carousel__track {
  font-size: 0;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.carousel__item {
  display: inline-flex;
  padding-left: 16px;
  padding-right: 16px;
  vertical-align: top;
  white-space: normal;
}

.carousel__item--desktop-in-1 {
  width: 100%;
}

.carousel__item--desktop-in-2 {
  width: 50%;
}

.carousel__item--desktop-in-3 {
  width: 33.3333333333%;
}

.carousel__item--desktop-in-4 {
  width: 25%;
}

.carousel__item--desktop-in-5 {
  width: 20%;
}

.img-fluid {
  cursor: pointer;
  /* width: 100%;
    height: 500px;
    object-fit: cover;
    z-index: -100; */
}

.videoPlay {
  width: 100%;
  height: 600px;
}

.button-sq_shadow {
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  aspect-ratio: 1/1;
  cursor: pointer;
  background-color: #fff;
}

.button-sq_shadow:hover {
  background-color: #f5f5f7;
  transition: 250ms ease-out;
}

.arrow_right_history:hover {
  transition: 0.3s all;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  padding: 2rem;
}

.button-dahsboard_icon {
  font-size: 45px !important;
}

.grey-bg {
  background-color: #eeeeee;
}

.socials img {
  filter: brightness(100%);
  transition: 0.3s ease-out;
}

.socials img:hover {
  filter: brightness(85%);
  transition: 0.3s ease-out;
}

.nexcard-img {
  max-height: 150px;
  object-fit: contain;
}

@media only screen and (min-width: 1200px) {
  video {
    background-image: linear-gradient(to right, #010b6b, #2271c1);
  }
}

@media only screen and (max-width: 768px) {
  .nexcard {
    padding: 1rem !important;
  }

  .nexcard-img {
    max-height: 100%;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    object-fit: contain;
  }
}

.icon-darkblue {
  font-size: 2rem;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  background: #0056a2;
  color: #fff;
  border-radius: 0.8em;
  margin: 1rem;
}

.icon-blue {
  font-size: 2rem;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  background: #2c8ee1;
  color: #fff;
  border-radius: 0.8em;
  margin: 1rem;
}

.icon-blue-border {
  font-size: 2rem;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  background: #0056a2;
  color: #fff;
  border-radius: 0.8em;
  margin: 1rem;
}

.icon-grey {
  font-size: 2em;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  background: #707070;
  color: #fff;
  border-radius: 0.8em;
  margin: 1rem;
}

.arrow_right {
  font-size: 3.5rem;
  text-align: center;
  color: #0056a2;
}

.deco-container {
  /* container that holds all decoration within  */
  position: absolute;
  top: 60px;
  right: 62px;
  width: 0px;
  height: 0px;
  z-index: 49;

  background-color: red;
}
</style>
