var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.retrieveData),expression:"retrieveData"}],staticClass:"menu-body pt-2",attrs:{"infinite-scroll-disabled":"busy","infinite-scroll-distance":"5"}},[_c('div',{staticClass:"menu-activity-collection pt-1"},[_c('PageLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageLoading),expression:"pageLoading"}],attrs:{"loading":_vm.pageLoading,"replace_msg":"Fetching History"}}),_vm._l((_vm.lists),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading),expression:"!pageLoading"}],key:index,staticClass:"p-1 border-bottom"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"fw-bold text-darkgrey"},[_vm._v(" "+_vm._s(item.name)+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b',{staticClass:"text-muted fs-small"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]),_c('p',{staticClass:"text-muted fs-small"},[_vm._v(" "+_vm._s(_vm.extractFirstName(item.creator.name))+" ")])]),_c('div',{staticClass:"col"},[(item.completedPerCentage >= 100 || true)?_c('p',{class:[
                'btn w-100',
                item.completedPerCentage >= 100
                  ? 'btn-primary-outlined'
                  : 'btn-warning-outlined'
              ],on:{"click":function($event){item.completedPerCentage >= 100 ||
                _vm.allowOpen.includes(item.type ?? false)
                  ? _vm.openCase(item)
                  : _vm.$toast.warning('Still in progress.')}}},[_vm._v(" "+_vm._s(item.completedPerCentage >= 100 ? "Open" : "Processing")+" "),(item.completedPerCentage < 100)?_c('b-spinner',{staticClass:"spinner",attrs:{"label":"Spinning"}}):_vm._e()],1):_vm._e()])])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading && _vm.lists.length === 0),expression:"!pageLoading && lists.length === 0"}],staticClass:"empty-placeholder"},[_c('div',[_c('img',{staticClass:"mb-3",attrs:{"src":require("@/assets/noData.png"),"alt":"No Data Image"}}),_c('p',{staticClass:"text-center text-blue font-weight-bold"},[_vm._v(" "+_vm._s("No Record")+" ")])])])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }