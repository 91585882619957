<template>
  <b-modal
    id="welcome-abroad"
    centered
    size="lg"
    hide-header
    hide-footer
    @hidden="updateLocalStorage"
  >
    <div style="padding: 15px">
      <div class="row">
        <div class="col">
          <h5 class="text-center"><b>Welcome to NexLaw!</b></h5>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <p class="text-center">
            To help you get started smoothly, we've prepared an onboarding
            video that covers all the essentials.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <video
            poster="@/assets/video/WelcomeAbroad.png"
            class="rounded shadow img-fluid"
            controls
            controlslist="nodownload noplaybackrate"
            style="width: 100%!important; height: auto!important;"
          >
            <source
              src="https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/Nexlaw+Onboarding+Video.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <a href="/tutorial" @click="updateLocalStorage">
            <button class="btn btn-primary w-100">Watch Tutorials</button>
          </a>
        </div>
        <div class="col-7 col-lg-6">
          <a href="https://www.nexlaw.ai/blog/#guideblogs" target="_blank">
            <button class="btn btn-primary w-100">
              I prefer written guides
            </button>
          </a>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
    },
  },

  methods: {
    updateLocalStorage() {
      localStorage.setItem(
        `firstTimeLogin ${JSON.parse(localStorage.getItem("userInfo")).email}`,
        false
      );
    },
  },

  mounted() {
    if (this.show) {
      this.$bvModal.show("welcome-abroad");
    }
  },
};
</script>

<style></style>
