<template>
  <div class="wrapper d-flex mt-3 float-right">

    <!-- <span :class="['material-symbols-outlined pulsate-fwd', keyframe[24] ? 'fade-in' : 'opacity-0']">gavel</span> -->
    <span
      :class="['img', keyframe[24] ? 'fade-in': 'opacity-0', {'gavel-animation': keyframe[25]}]"
      v-html="SVGs.gavel">
    </span>
    <span
      :class="['img plate', keyframe[22] ? 'fade-in': 'opacity-0']"
      v-html="SVGs.plate">
    </span>

    &nbsp;&nbsp;&nbsp;

    <p v-for="(word, index) in bodyText"
      :key="index"
      :class="[keyframe[index] ? 'bounce-in-top': 'opacity-0']">
      {{ word==" " ? '&nbsp;' : word }}
    </p>
  </div>
</template>
<script>
export default {
  data() { return {
    keyframe: [],
    bodyText: "Happy Labour Day To All!",
    SVGs: {
      gavel: `<svg fill="#094890" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="796 796 200 200" enable-background="new 796 796 200 200" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M936.46,868.211c4.939,4.94,12.946,4.938,17.884,0c4.94-4.938,4.941-12.946,0-17.885l-50.619-50.622 c-4.94-4.94-12.947-4.938-17.887,0c-4.939,4.938-4.939,12.946,0,17.885L936.46,868.211z"></path> <path d="M817.588,885.841c-4.938-4.94-12.946-4.94-17.884,0c-4.939,4.938-4.941,12.945,0,17.885l50.619,50.619 c4.94,4.94,12.946,4.94,17.887,0c4.94-4.938,4.94-12.946,0-17.886L817.588,885.841z"></path> <path d="M989.605,963.929l-75.06-63.993c3.388-3.902,6.598-7.964,9.563-12.216l1.549-2.22c3.202-4.592,2.651-10.82-1.308-14.779 L883.328,829.7c-3.959-3.959-10.187-4.509-14.779-1.307l-3.618,2.523c-13.254,9.244-24.769,20.759-34.014,34.012l-2.524,3.619 c-3.203,4.593-2.653,10.822,1.306,14.78l41.023,41.021c3.959,3.959,10.186,4.51,14.778,1.307l2.22-1.549 c4.25-2.964,8.312-6.172,12.213-9.558l63.994,75.058c3.297,3.867,8.06,6.181,13.136,6.38c5.079,0.202,10.01-1.725,13.603-5.317 c3.595-3.593,5.522-8.523,5.32-13.602C995.785,971.988,993.471,967.227,989.605,963.929z"></path> </g> </g></svg>`,

      plate: `<svg fill="#094890"xmlns="http://www.w3.org/2000/svg" width="20" height="4"><rect x="0" y="0" width="20px" height="4px" rx="3" ry="3"/></svg>`,
    },
  }},

  created() {
    for ( var i=0; i < this.bodyText.length + 2; i++ ) {
      var interval = 90;

      setTimeout(() => {
        this.keyframe.push(true);
      }, interval*i);
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.wrapper {
  /* for additional positioning within the deco-container */
  position: relative;
  right: 10px;

}

/* styling and animating elements */
p {
  display: inline;
  margin-top: 6px;

  font-family: 'Shantell Sans';
  font-size: 20px;
  font-weight: 600;
  color: #094890;
}

.img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.img.plate {
  position: absolute;
  top: 20px;
  right: 280px;
}

.material-symbols-outlined {
  font-size: 20px;
  font-weight: 600;
  color: #094890;
  user-select: none;
}

/* list keyframes here for future usage */
/**
 *  @bounce-in-top
 *  @fulsate-fwd
 *  @fade-in
 *  @rotate-90-br-ccw // modified to gavel's animation
 */

@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
.bounce-in-top {
  -webkit-animation: bounce-in-top 1.1s both;
          animation: bounce-in-top 1.1s both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.pulsate-fwd {
	-webkit-animation: pulsate-fwd 2s ease-in-out infinite ;
          animation: pulsate-fwd 2s ease-in-out infinite ;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes rotate-90-br-ccw {
  0% {
    -webkit-transform: rotate(30);
            transform: rotate(30);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  50% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  55% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  65% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  70% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes rotate-90-br-ccw {
  0% {
    -webkit-transform: rotate(30);
            transform: rotate(30);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  80% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  85% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  95% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
.gavel-animation {
	-webkit-animation: rotate-90-br-ccw 1.5s cubic-bezier(1, 0.050, 0.855, 0.060) infinite;
          animation: rotate-90-br-ccw 1.5s cubic-bezier(1, 0.050, 0.855, 0.060) infinite;
}
</style>